import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import XYZ from 'ol/source/XYZ'
import TileWMS from 'ol/source/TileWMS'
import KML from 'ol/format/KML'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector';
import {Fill, Stroke, Style, Text, Icon} from 'ol/style';
import { VectorTile } from "ol"
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';
import MVT from 'ol/format/MVT';
import Feature from "ol/Feature";
import {bbox as bboxStrategy} from 'ol/loadingstrategy';


var sale_polygon_style = new Style({
  stroke: new Stroke({
    width: 2,
    color: "#FF0000"
  })
})

var iconscale=0.08
var APTiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
    //anchorXUnits: 'fraction',
    //anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/APT.png',//'img/home-map-marker.png',
  }),
});
var ICIiconStyle = new Style({
  image: new Icon({
   // anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/ICI.png',//'img/home-map-marker.png',
  }),
});
var OFFiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/OFF.png',//'img/home-map-marker.png',
  }),
});
var RLNiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
    //anchorXUnits: 'fraction',
    //anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/RLN.png',//'img/home-map-marker.png',
  }),
});
var HOTiconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 46],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/HOT.png',//'img/home-map-marker.png',
  }),
});
var INDiconStyle = new Style({
  image: new Icon({
   // anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/IND.png',//'img/home-map-marker.png',
  }),
});
var RETiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/RET.png',//'img/home-map-marker.png',
  }),
});
var NOTYPEiconStyle = new Style({
  image: new Icon({
   // anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img//NOTYPE.png',//'img/home-map-marker.png',
  }),
});

var customStyleFunction=function(feature,resolution){
  //console.log("feature:",feature)
  //console.log('feature:',feature.get('InvNum').split("-")[1])
  var iconStyle=null
  //if (Date.parse(feature.get('MatDate'))>=Date.parse('2023/01/01')){
    //let LTV=(feature.get('MAmt')/feature.get('Price')).toFixed(2)
    //console.log("LTV:",LTV)
    iconscale=(typeof((feature.get('MAmt')/feature.get('Price')))=="undefined"| (feature.get('MAmt')/feature.get('Price'))> 1) ? 0.09:0.09*(feature.get('MAmt')/feature.get('Price'))
   
    //console.log("iconscale:",iconscale)
  switch  (feature.get('InvNum').split("-")[1]) {
  case 'RLN':
    RLNiconStyle.getImage().setScale(iconscale);
    iconStyle=RLNiconStyle;
    break;
  case 'APT':
    APTiconStyle.getImage().setScale(iconscale);
    iconStyle=APTiconStyle;
    break;
  case 'IND':
    INDiconStyle.getImage().setScale(iconscale);
    iconStyle=INDiconStyle;
    break;
  case 'OFF':
    OFFiconStyle.getImage().setScale(iconscale);
    iconStyle=OFFiconStyle;
    break;
  case 'RET':
    RETiconStyle.getImage().setScale(iconscale);
    iconStyle=RETiconStyle;
    break;
  case 'ICI':
    ICIiconStyle.getImage().setScale(iconscale);
    iconStyle=ICIiconStyle;
    break;
  case 'HOT':
    HOTiconStyle.getImage().setScale(iconscale);
    iconStyle=HOTiconStyle;
    break;
  default:
    NOTYPEiconStyle.getImage().setScale(iconscale);
    iconStyle=NOTYPEiconStyle;
  };
  return iconStyle
}

var customStyleFunction2=function(feature,resolution){
  //console.log("feature:",feature)
  //console.log('feature:',feature.get('InvNum').split("-")[1])
  var iconStyle=null
  //if (Date.parse(feature.get('MatDate'))>=Date.parse('2023/01/01')){
    //let LTV=(feature.get('MAmt')/feature.get('Price')).toFixed(2)
    //console.log("LTV:",LTV)
    //iconscale=(typeof((feature.get('MAmt')/feature.get('Price')))=="undefined"| (feature.get('MAmt')/feature.get('Price'))> 1) ? 0.09:0.09*(feature.get('MAmt')/feature.get('Price'))
   
    //console.log("iconscale:",iconscale)
  switch  (feature.get('InvNum').split("-")[1]) {
  case 'RLN':
    RLNiconStyle.getImage().setScale(iconscale);
    iconStyle=RLNiconStyle;
    break;
  case 'APT':
    APTiconStyle.getImage().setScale(iconscale);
    iconStyle=APTiconStyle;
    break;
  case 'IND':
    INDiconStyle.getImage().setScale(iconscale);
    iconStyle=INDiconStyle;
    break;
  case 'OFF':
    OFFiconStyle.getImage().setScale(iconscale);
    iconStyle=OFFiconStyle;
    break;
  case 'RET':
    RETiconStyle.getImage().setScale(iconscale);
    iconStyle=RETiconStyle;
    break;
  case 'ICI':
    ICIiconStyle.getImage().setScale(iconscale);
    iconStyle=ICIiconStyle;
    break;
  case 'HOT':
    HOTiconStyle.getImage().setScale(iconscale);
    iconStyle=HOTiconStyle;
    break;
  default:
    NOTYPEiconStyle.getImage().setScale(iconscale);
    iconStyle=NOTYPEiconStyle;
  };
  return iconStyle
}

var mortgagevlayer=function(){
  var  vectorlayer = new VectorLayer({
    visible:false,
    name:'mortgagevlayer',
    title:'mortgage layer',
    style:customStyleFunction,
    source: new VectorSource({
      url: function (extent) {
        //for David:mortgage20232024M2017Sep2022S
        //for mb :mortgageMay22Feb23M2017Sep2023S
        //mortgage20232024M2017Sep2022SV2
        return (
          'https://geoterratone.com/geoserver/wfs?service=WFS&' +
          'version=1.1.0&request=GetFeature&typename=Terradots:mortgage20232024M20172022SToronto&' +
          'outputFormat=application/json&srsname=EPSG:3857&' +
          'bbox=' +
          extent.join(',') +
          ',EPSG:3857'
        );
      },
      strategy: bboxStrategy,
      format: new GeoJSON(),
    }),

  })
  return vectorlayer
};

var realnet2017vlayer=function(){
  var  vectorlayer = new VectorLayer({
    visible:true,
    name:'realnet2017vlayer',
    title:'realnet 2017 layer',
    style:customStyleFunction2,
    source: new VectorSource({
      url: function (extent) {
        //for David:mortgage20232024M2017Sep2022S
        //for mb :mortgageMay22Feb23M2017Sep2023S
        //mortgage20232024M2017Sep2022SV2
        return (
          'https://geoterratone.com/geoserver/wfs?service=WFS&' +
          'version=1.1.0&request=GetFeature&typename=Terradots:RealNetToronto2017&' +
          'outputFormat=application/json&srsname=EPSG:3857&' +
          'bbox=' +
          extent.join(',') +
          ',EPSG:3857'
        );
      },
      strategy: bboxStrategy,
      format: new GeoJSON(),
    }),

  })
  return vectorlayer
};



var torontoparcelmapMVT=function(){
  var vectortilelayer=new VectorTileLayer({
    name:'GreaterTorontoParcel',
    visible:true,
    minZoom:14,
    maxZoom:20,
    source: new VectorTileSource({
      tilePixelRatio: 1, // oversampling when > 1
      tileGrid: createXYZ({maxZoom: 30}),
      format: new MVT(   {featureClass: Feature} ), 
      url: 'https://geoterratone.com:/geoserver/Terradots/gwc/service/tms/1.0.0/' + 'GreaterTorontoParcel' +
          '@EPSG%3A'+'900913'+'@pbf/{z}/{x}/{-y}.pbf'
    })
  })
  return vectortilelayer
}
var torontosaletrxMVT=function(){
  var vectortilelayer=new VectorTileLayer({
    minZoom:12,
    maxZoom:20,
    title:'Toronto Sale APT 2022-MVT',
    name:'Toronto Sale APT 2022-MVT',
    visible:false,
    style:sale_polygon_style,
    source: new VectorTileSource({
      tilePixelRatio: 1, // oversampling when > 1
      tileGrid: createXYZ({maxZoom: 19}),
      format: new MVT(   {featureClass: Feature} ), 
      url: 'https://geoterratone.com:/geoserver/Terradots/gwc/service/tms/1.0.0/' + 'TorontoRealNet2022Oct_APT' +
          '@EPSG%3A'+'900913'+'@pbf/{z}/{x}/{-y}.pbf'
    })
  })
  return vectortilelayer
}
var torontosaletrx=function(){
  var parcelLayer = new TileLayer({
      title:'Toronto Sale APT 2022',
      name:'Toronto Sale APT 2022',
      displayInLayerPopup:'0',
      opacity:0.7,
      minZoom:10,
      preload: Infinity,
      bgcolor:'0x80BDE3',
      
      source: new TileWMS({
    
        url:
   
          "https://geoterratone.com:/geoserver/Terradots/wms?version=1.1.0",
        crossOrigin: null,
        params: {
          LAYERS:"TorontoRealNet2022APTParcel",
        }
      })
      
    });
  return parcelLayer;
}

var indmap2=function(){
  var   indlayer2 = new VectorLayer({

    source: new VectorSource({
      url: 'data/MannaFund200_blue.kml',
      format: new KML(),
    }),
    title:'Selected Parcels 2',
    name:'SelectedParcels2',

  })
  /*
  indlayer.getSource().on('change', function(evt){
    const source = evt.target;
    if (source.getState() === 'ready') {
      const numFeatures = source.getFeatures().length;
      console.log("Count after change: " + numFeatures);
    }
  });
  */
 /*
  indlayer.once('change', function () {
    indlayer.getSource().forEachFeature(function (feature) {
        console.log(feature);
    });
   
});
 */
  return indlayer2
};
var indmap=function(){
  var   indlayer = new VectorLayer({

    source: new VectorSource({
      url: 'data/TorontoRealNet2022Oct_APT_data.kml',
      format: new KML(),
    }),
    title:'Toronto RealNet APT 2022',
    name:'Toronto RealNet APT 2022',
    visible:false,

  })
  /*
  indlayer.getSource().on('change', function(evt){
    const source = evt.target;
    if (source.getState() === 'ready') {
      const numFeatures = source.getFeatures().length;
      console.log("Count after change: " + numFeatures);
    }
  });
  */
 /*
  indlayer.once('change', function () {
    indlayer.getSource().forEachFeature(function (feature) {
        console.log(feature);
    });
   
});
 */
  return indlayer
};

var streetmap=function(){
    var mapbaselayer=null;
            mapbaselayer=new TileLayer({
                source: new OSM(),
                type:'base',
                baseLayer:true
            })
    return mapbaselayer;
};
var googlemap=function(){
  var mapbaselayer=null;
          mapbaselayer=new TileLayer({
              source: new XYZ({
                attributions: '© <a href="https://cloud.google.com/maps-platform/terms/" target="_blank">Powered By Google. </a>',
                //attributionsCollapsible: false,
                url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
              }),
              type:'base',
              baseLayer:true,
          })
  return mapbaselayer;
};
var satellitemap=function(){
    var additonallayers=null;
            additonallayers=new TileLayer({
                title:'Satellite view',
                name:'GSatellite',
                visible:false,
              source: new XYZ({
                attributions: ['Powered by Esri',
                               'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                maxZoom: 23
              })
            });
    return additonallayers;
}

var torontoparcelmap=function(){
  var parcelLayer = new TileLayer({
      title: 'Toronto Parcel lines',
      name:'GreaterTorontoParcelWMS',
      visible:true,
      //displayInLayerPopup:'0',
      opacity:0.5,
      minZoom:14,
      //preload: Infinity,
      //bgcolor:'0x80BDE3',
      source: new TileWMS({
        url:
          "https://geoterratone.com:/geoserver/Terradots/wms?version=1.1.0",
          
        crossOrigin: null,
        params: {
          LAYERS:"GreaterTorontoParcel",
          TILED:true,
          //FORMAT:'application/vnd.mapbox-vector-tile',
        }
      })
      
    });
  return parcelLayer;
}
var bookmarksmap=function(){
   var style = [
    new Style({
      stroke: new Stroke({
        color: "black",
        width: 4,
      }),
      fill: new Fill({
        color: "green",
      }),
    }),
  ];
  var bookmarkLayer=new VectorLayer({
    title:'Bookmarks',
    name:'Bookmarks',
    source: new VectorSource(),
    style : style ,
    visible:false
  
  })
  
  return bookmarkLayer
}


var mapconfig={

    //CBRE Toronto
    x:-79.3936137,
    y:43.6882361,

    zoom:12,          
    //streetmap:streetmap(),
    googlemap:googlemap(),
    satellitemap:satellitemap(),
    bookmarksmap:bookmarksmap(),
    //indmap:indmap(),
    //indmap2:indmap2(),
    torontoparcelmap: torontoparcelmap(),
    //torontoparcelmapMVT:torontoparcelmapMVT(),
    //torontosaletrxMVT:torontosaletrxMVT(),
    //torontosaletrx:torontosaletrx(),
    mortgagevlayer:mortgagevlayer(),
    //realnet2017vlayer:realnet2017vlayer()

    

};
export default mapconfig



