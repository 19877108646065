<template>
  <v-ons-splitter>
    <v-ons-splitter-side
      width="70%"
      collapse=""
      side="left"
      :open.sync="openSide"
    >
      <v-ons-page>
        <v-ons-card>
          <div style="display: table; margin: 0 auto">
            <img class="avatar" :src="userProfile.photoURL" />

            <label for="elemFileUpload" class="custom-file-upload">
              <input
                type="file"
                accept="image/jpeg,image/png"
                name="elemFileUpload"
                id="elemFileUpload"
                @change="fileUploadChange"
              />
              <v-ons-icon icon="md-upload"></v-ons-icon>
            </label>
          </div>

          <v-ons-list modifier="inset">
            <v-ons-list-item
              tappable
              @click="this.showPrompt"
              style="height: 30px; min-height: 30px"
            >
              <div
                style="
                  color: black;
                  font-size: large;
                  font-weight: bold;
                  text-transform: capitalize;
                "
              >
                {{ currentUser.displayName }}
                <!--{{currentUser.displayName}} -->
              </div>
            </v-ons-list-item>
            <v-ons-list-item style="line-height: 1">
              <div style="color: blue; font-size: small">
                {{ currentUser.email }}
              </div>
            </v-ons-list-item>
          </v-ons-list>
          <v-ons-list>
            <v-ons-list-item @click="RefreshMap()">
              <div class="right">
                <v-ons-icon icon="fa-sync" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">Refresh Map</div>
            </v-ons-list-item>
            <v-ons-list-item @click="router.push('/logout')">
              <div class="right">
                <v-ons-icon icon="fa-sign-out-alt" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">Logout</div>
            </v-ons-list-item>
            <v-ons-list-item
              @click="
                $ons.notification.toast('TerratonePull Version 2.2.0 Toronto', {
                  timeout: 2000,
                })
              "
            >
              <div class="right">
                <v-ons-icon icon="fa-info" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">About</div>
            </v-ons-list-item>
          </v-ons-list>
        </v-ons-card>
      </v-ons-page>
    </v-ons-splitter-side>

    <v-ons-splitter-content>
      <v-ons-page id="olmap2">
        <div ref="mapView" class="map-view" id="mapView">
          <!-- Popup -->
          <div ref="popup" class="ol-popup">
            <a @click="popupcloser" class="ol-popup-closer"></a>
            <div class="ol-popup-content" v-html="popupContent"></div>
            <div id="popupbottombar">
              <div style="float: left; font-size: 20px">
                <i
                  class="fas fa-street-view"
                  style="font-size: 20px; padding-left: 0px"
                  @click="showStreetviewPage()"
                ></i>
              </div>
              <!-- Please Remove visiblity:hidden after Demo app for Manna Fund 13 April 2022 -->
              <div
                style="float: right; font-size: 18px"
                v-bind:class="[
                  isBookmarked ? 'fa fa-bookmark' : 'far fa-bookmark',
                ]"
                @click="toggleClass()"
              ></div>
            </div>
          </div>
        </div>

        <!-- Right sidebars -->
        <div id="summarysidebar" class="sidebar right" @click="clickSumSidebar">
          <div id="db1"><Pie :chart-data="chartData" /></div>
          <div id="ChartSection">
            <v-ons-row>
              <v-ons-col>
                <div id="plot_div1"></div>
              </v-ons-col>
              <v-ons-col>
                <div id="plot_div2"></div>
              </v-ons-col>
            </v-ons-row>
          </div>
          <!-- dual range slider -->
          <div id="slider" class="MultiRangeSliderContainer">
            <MultiRangeSlider
              :min="1"
              :max="762"
              :minValue="dayBarMinValue"
              :maxValue="dayBarMaxValue"
              :labels="monthNames"
              :min-caption="dayMinCaption"
              :max-caption="dayMaxCaption"
              :step="1"
              @input="updateDayValues"
            />
          </div>
          <div id="db3"><div v-html="html_mortgageSummary"></div></div>
          <div id="db2"><div v-html="html_mortgageLegend"></div></div>
        </div>

        <v-ons-speed-dial
          position="bottom right"
          direction="up"
          :open.sync="toggle"
          @click="toggle = !toggle"
        >
          <v-ons-fab>
            <i :class="[toggle ? 'fa-times' : 'fa-plus', 'fa']"></i>
          </v-ons-fab>
          <v-ons-speed-dial-item>
            <v-ons-icon icon="md-comment" @click="showNoteForm()"></v-ons-icon>
          </v-ons-speed-dial-item>
          <v-ons-speed-dial-item>
            <v-ons-icon
              icon="md-bookmark"
              @click.prevent="router.push('bookmark')"
            ></v-ons-icon>
          </v-ons-speed-dial-item>
        </v-ons-speed-dial>
        <v-ons-button
          modifier="quiet"
          class="mainmenu"
          :visible="true"
          @click="openSide = true"
        >
          <v-ons-icon
            style="color: black"
            size="40px"
            icon="fa-caret-square-right"
          ></v-ons-icon>
        </v-ons-button>
        <v-ons-dialog cancelable :visible.sync="createpostVisible">
          <createpost
            @closeWin="createpostVisible = false"
            v-bind:ownerinfo="ownerinfo"
            v-bind:propinfo="propinfo"
            v-bind:selectedCentre="selectedCentre"
            v-bind:selectedAddress="selectedAddress"
            v-bind:hash="hash"
          ></createpost>
        </v-ons-dialog>
      </v-ons-page>
      <!--
      <v2-cupertino
        :drawerOptions="{
          fitScreenHeight: true,
          buttonDestroy: false,
          simulateTouch: true,
          preventClicks:true,
          initialBreak:'bottom',
        }"
      >     

      <propertyinfo>owner</propertyinfo>
      </v2-cupertino>
           -->
    </v-ons-splitter-content>
  </v-ons-splitter>
</template>
<script>
import "ol/ol.css";
import { Map, View } from "ol";
import * as olControl from "ol/control";
import * as olProj from "ol/proj";
import "../../node_modules/ol-geocoder/dist/ol-geocoder.min.css";
import Geocoder from "ol-geocoder";
import { Style } from "ol/style";
import { Fill, Stroke, Icon } from "ol/style";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Control from "ol/control/Control";
import { circular } from "ol/geom/Polygon";
import { fromLonLat } from "ol/proj";
import { containsCoordinate } from "ol/extent";
import Overlay from "ol/Overlay";
import Select from "ol/interaction/Select";
import Geometry from "ol/geom/Geometry";

import "ol-ext/control/Timeline.css";
import Timeline from "ol-ext/control/Timeline";

import fb from "../firebase";
const geofire = require("geofire-common");

//for API call
import axios from "axios";

import { VectorTile } from "ol";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
//import {Fill, Stroke, Style, Text, Icon} from 'ol/style';
import mapconfig from "../config/mapconfig";

import MapFunctions from "../MapFunctions";
//import recentTransactions from "../recent-transactions";

// Import the EventBus we just created.
import EventBus from "../config/event-bus";

import { mapState } from "vuex";

import router from "../router";
import createpost from "../components/createpost";
import state from "../state";
//import V2Cupertino from "v2-cupertino";
//import propertyinfo from "../components/propertyinfo.vue"

import MultiRangeSlider from "../components/MultiRangeSlider.vue";

import { Bar, Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import * as dfd from "danfojs";
import { readCSV, DataFrame } from "danfojs";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale
);

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
const proxyurl2 = "https://index2.laurel.workers.dev/?";

// For Perry const App_extent=[-13708636.322763294,6318548.145849912,-13706072.212231088,6321196.52292543]
// const App_extent=[-13691698.770829778,6311346.989584832,-13688330.310083484,6312792.094212464] // For Ilya
//Map variables
let map = null;
// Variables for Overlay new
let popup;
export default {
  mixins: [MapFunctions],
  data() {
    return {
      //
      popupContent: "",
      isBookmarked: false,
      toggle: false,
      router: router,
      createpostVisible: false,
      openSide: false,
      ownerinfo: null,
      propinfo: null,
      selectedParcel: null,
      selectedCentre: null,
      name: "",
      lonlat: null,
      selectedAddress: null,
      bookmarksFeatures: null,
      hash: null,
      newPhoto: null,
      selectedAssetType: [
        "APT",
        "HOT",
        "ICI",
        "IND",
        "OFF",
        "RLN",
        "RET",
        "OTH",
      ],
      mTotalPoints: 0,
      mSumPrice: 0,
      mSumLoan: 0,
      mAvgPrice: 0,
      mAvgLoan: 0,
      mAvgLTV: 0,
      mMaturity: "",
      html_mortgageSummary: "",
      html_mortgageLegend: "",
      featureList: [],
      chartData: {
        labels: ["Residental Land", "Retail", "Office", "ICI"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [20, 80, 10, 20],
          },
        ],
      },
      dayBarMinValue: 32,
      dayBarMaxValue: 333,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser", "bookmarks"]),

    monthNames() {
      return ["2023", "Apr", "Jul", "Oct", "2024", "Apr", "Jul", "Oct", "2025"];
    },
    dayMinCaption() {
      let d = new Date("01/01/2023");
      let dd = new Date("1-Jan-" + d.getFullYear());
      dd.setDate(this.dayBarMinValue);
      return dd.toLocaleDateString("en-US",
        {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
    },
    dayMaxCaption() {
      let d = new Date("01/01/2023");
      let dd = new Date("1-Jan-" + d.getFullYear());
      dd.setDate(this.dayBarMaxValue);
      return dd.toLocaleDateString("en-US",
        {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
    },
  },
  watched: {},
  components: {
    createpost: createpost,
    Bar,
    Pie,
    MultiRangeSlider,
    //V2Cupertino,
    //propertyinfo,
  },
  methods: {
          // converts number to string representation with K and M.
      // toFixed(d) returns a string that has exactly 'd' digits
      // after the decimal place, rounding if necessary.
      NumFormatter(num) {
        if (num >= 999 && num < 1000000) {
          return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
        } else if (num < 900) {
          return num; // if value < 1000, nothing to do
        }
      },
    FilterByDateRange() {
      
      var NoOfFeatures = mapconfig.mortgagevlayer.getSource().getFeatures()
        .length;
      //console.log("NoOfFeatures:",NoOfFeatures)
      var cachedFeatures = [];
      var cachedAttributes = [];
      //var eStartDate = new Date(e.dateStart);
      //var eEndDate = new Date(e.dateEnd);
var eStartDate=new Date(this.dayMinCaption)
var eEndDate=new Date(this.dayMaxCaption)
      var eStartDateString = new Date(this.dayMinCaption).toLocaleDateString(
        "en-US",
        {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        }
      );
      var eEndDateString = new Date(this.dayMaxCaption).toLocaleDateString("en-US", {
        year: "2-digit",
        month: "short",
        day: "2-digit",
      });
      var sumPrice = 0;
      var sumLoan = 0;
      let that=this
      map.getLayers().forEach(function(lyr) {
        //console.log(lyr.get("name"));
        if (lyr.get("name") === "mortgagevlayer") {
          lyr.getSource()
          .getFeatures()

     // mapconfig.mortgagevlayer
     //   .getSource()
    //    .getFeatures()
        .forEach(function(f) {
          //console.log("feature of mlayer in RangeSlider:",new Date(f.get('MDate')),eStartDate,eEndDate)
          var fMatDate = new Date(f.get("MDate"));

          if (fMatDate > eStartDate && fMatDate < eEndDate) {
            if (that.selectedAssetType.includes(f.get("AssetType"))) {
              f.setStyle();
              if (!cachedFeatures.includes(f)) {
                sumPrice = sumPrice + f.get("Price");
                sumLoan = sumLoan + f.get("MAmt");
                cachedFeatures.push(f);
                cachedAttributes.push({
                  AssetType: f.get("AssetType"),
                  Price: f.get("Price"),
                  Loan: f.get("MAmt"),
                  City: f.get("City"),
                  InvNum: f.get("InvNum"),
                });
              }
            }
          } else {
            if (that.selectedAssetType.includes(f.get("AssetType"))) {
              f.setStyle([]);
            }
          }
        });

      //console.log("cached Features:",cachedFeatures)
      //console.log("sum of cached Feature:",cachedFeatures.length,sumPrice,sumLoan,(sumPrice/cachedFeatures.length).toFixed(2),(sumLoan/cachedFeatures.length).toFixed(2),((sumLoan/sumPrice)*100).toFixed(2))
      that.mTotalPoints = cachedFeatures.length;
      //cachedAttributes=cachedAttributes.sort(function(a,b){return a.AssetType > b.AssetType})
      //console.log("cachedAttributes:", cachedAttributes);

      that.mSumPrice = isNaN(sumPrice) ? "" : "$" + that.NumFormatter(sumPrice);
      that.mSumLoan = isNaN(sumLoan) ? "" : "$" + that.NumFormatter(sumLoan);
      that.mAvgPrice = (sumPrice / cachedFeatures.length).toFixed(2);
      that.mAvgLoan = (sumLoan / cachedFeatures.length).toFixed(2);
      that.mAvgLTV = ((sumLoan / sumPrice) * 100).toFixed(2);
      that.mMaturity = eStartDateString + " - " + eEndDateString;

      that.mMaturity = that.mMaturity.replaceAll(",", " ");

      that.mAvgPrice = isNaN(that.mAvgPrice)
        ? "$0"
        : "$" + that.NumFormatter(that.mAvgPrice);
      that.mAvgLoan = isNaN(that.mAvgLoan)
        ? "$0"
        : "$" + that.NumFormatter(that.mAvgLoan);
      that.mAvgLTV = isNaN(that.mAvgLTV) ? "0%" : that.mAvgLTV + "%";

      //console.log("mAvgLTV:",that.mAvgLTV)
      //that.html_mortgageSummary="<div># Records:" + that.mTotalPoints + "</div><div>LTV:" + that.mAvgLTV + "</div><div>Average Price:" + that.mAvgPrice+"</div>" + "<div>Average Loan:" + that.mAvgLoan +"</div>"
      var mortgageSummaryTable = `<div id='rcorners3' style='text-align: left;'><table class="popuptable">
  <tr >
    <td >Properties</td>
    <th>${that.mTotalPoints}</th>
  </tr>
  <tr >
    <tr >
    <td>Maturity Range</td>
    <th>${that.mMaturity}</th>
  </tr>
    <td>Total Loan</td>
    <th >${that.mSumLoan}</th>
  </tr>
  <tr>
    <td >Total Price</td>
    <th >${that.mSumPrice}</th>
  </tr>
  <tr >
  <td >Average Loan</td >
  <th w>${that.mAvgLoan}</th> 
  </tr>
  <tr>
  <td >Average Price</td>
  <th >${that.mAvgPrice}</th>
  </tr>
 
  <tr>
  <td>LTV</td>
  <th>${that.mAvgLTV}</th>
  </tr>
    <tr>
  <td>LTAV</td>
  <th>Not Available</th>
  </tr>
</table></font></div>`;

      that.html_mortgageSummary = mortgageSummaryTable;
              }
      });
    },
    updateDayValues(e) {
      this.dayBarMinValue = e.minValue;
      this.dayBarMaxValue = e.maxValue;
      this.popupcloser();
       this.FilterByDateRange()
    },

    computeGeoHash(aCentre) {
      var selectcentre_lonlat = olProj.transform(
        [aCentre.flatCoordinates[0], aCentre.flatCoordinates[1]],
        "EPSG:3857",
        "EPSG:4326"
      );
      var hash = geofire.geohashForLocation([
        selectcentre_lonlat[1],
        selectcentre_lonlat[0],
      ]);
      return hash;
    },
    chooseFile() {
      this.$ons.notification.alert({
        message:
          "Please select an image file with 200*200 pixel for best display.",
        callback: function() {
          document.getElementById("elemFileUpload").click();
        },
      });
    },
    clickSumSidebar(e) {
      //console.log("e on sumsidebar:",e)
      if (e.target.type == "checkbox") {
        //console.log("e.target:",e.target.id,e.target.checked)

        if (e.target.checked == false) {
          this.selectedAssetType = this.selectedAssetType.filter(function(el) {
            return el !== e.target.value;
          });
          //console.log("selectedAssetType:", this.selectedAssetType);
          let that = this;
          mapconfig.mortgagevlayer
            .getSource()
            .getFeatures()
            .forEach(function(f) {
              //console.log("feature of mlayer in RangeSlider:",new Date(f.get('MDate')).toUTCString())
              //console.log("e.target value:",e.target.value,f.get("InvNum").split("-")[1])
              if (f.get("AssetType") == e.target.value) {
                f.setStyle([]);
              }
            });
        } else if (e.target.checked == true) {
          this.selectedAssetType.push(e.target.value);
          //console.log("selectedAssetType:", this.selectedAssetType);
          var extent = map.getView().calculateExtent(map.getSize());
          var curStartDate = new Date(this.dayMinCaption);
          var curEndDate = new Date(this.dayMaxCaption);
          mapconfig.mortgagevlayer
            .getSource()
            .getFeatures()
            .forEach(function(f) {
              //console.log("feature of mlayer in RangeSlider:",new Date(f.get('MatDate')).toUTCString())
              //console.log("e.target value:",e.target.value,f.get("InvNum").split("-")[1])
              var featureMatDate = new Date(f.get("MDate"));
              if (f.get("AssetType") == e.target.value) {
                //if (containsExtent(extent,f.getGeometry().getExtent())){
                //console.log("MatDate:",featureMatDate,featureMatDate>= curStartDate)
                if (
                  featureMatDate >= curStartDate &&
                  featureMatDate <= curEndDate
                ) {
                  f.setStyle();
                }
                //}
              }
            });
        }
      }
    },
    fileUploadChange(e) {
      let file = e.target.files[0];
      let that = this;
      console.log("file", file);
      let filePic = file;
      let fileSize = file.size;
      if (fileSize > 2048 * 2048) {
        that.$ons.notification.alert("File size cannot exceed 4M!");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(filePic);
      reader.onload = function(e) {
        var data = e.target.result;
        var image = new Image();
        image.onload = function(e) {
          var width = image.width;
          var height = image.height;
          console.log("imagesize:", width, height);
          /*
           if (width== 200 || height==200) {
              that.newPhoto = file
              that.updateProfile()
           }
           else {
             that.$ons.notification.alert ("Image size should be:200*200!")
              return
           }*/
          that.newPhoto = file;
          that.updateProfile();
        };
        image.src = data;
      };
    },
    checkBookmarked: function() {
      var abookmark = null;
      //console.log("selectedParcel_id",this.selectedParcel_id)
      console.log("selected ObjectID:", this.selectedObjectId);
      console.log("selected hash:", this.hash);
      console.log("bookmarks", this.bookmarks);
      //abookmark=this.bookmarks.find(bookmark=>bookmark.data.parcel_id==this.selectedParcel_id)
      abookmark = this.bookmarks.find(
        (bookmark) => bookmark.data.hash == this.hash
      );
      console.log("abookmark", abookmark);
      this.isBookmarked = abookmark;
    },
    showPrompt() {
      let that = this;
      this.$ons.notification
        .prompt({
          message: "Enter your new user name:",
          title: "Update Profile",
          cancelable: true,
          buttonLabels: ["Cancel", "OK"],
        })
        .then(function(input) {
          if (input) {
            console.log("input:", input);
            that.name = input;
            that.updateProfile();
          }
        });
    },
    updateProfile() {
      let file = this.newPhoto;
      let extension = file.name.split(".").pop();
      let newfilename = this.currentUser.uid + "." + extension;
      let newfile = new File([file], newfilename);
      console.log("newfilename", newfile.name);
      var aDownLoadURL;
      var uploadTask = fb.storage
        .ref()
        .child(`images/${newfile.name}`)
        .put(newfile, { contentType: "image/png" });
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            aDownLoadURL = downloadURL;
            this.userProfile.photoURL = aDownLoadURL;
            console.log("userProfile", this.userProfile);
            this.$store.dispatch("updateProfile", this.userProfile);

            this.name = "";
          });
        }
      );

      // {
      //name: this.name !== '' ? this.name : this.userProfile.name,
      //title: this.title !== '' ? this.title : this.userProfile.title
      //  })
    },
    RefreshMap() {
      console.log("Refresh Map");
      this.$ons.notification.toast("Refreshing the map...", {
        timeout: 1000,
        animation: "fall",
      });
      map.updateSize();
      map.renderSync();
    },
    toggleClass: function(event) {
      this.isBookmarked = !this.isBookmarked;
      this.updateBookmark();
    },
    clearBookmarkLayer() {
      map.getLayers().forEach(function(lyr) {
        console.log(lyr.get("name"));
        if (lyr.get("name") === "Bookmarks") {
          lyr.getSource().clear();
        }
      });
    },
    deleteParcelonBookmarkLayer(bookmarkhash) {
      map.getLayers().forEach(function(lyr) {
        console.log(lyr.get("name"));
        if (lyr.get("name") === "Bookmarks") {
          //lyr.getSource().addFeatures([feature])
          console.log("bookmarkhash", bookmarkhash);
          console.log("all features:", lyr.getSource().getFeatures());
          var searchFeature = lyr.getSource().getFeatureById(bookmarkhash);
          console.log("searchFeature", searchFeature);
          lyr.getSource().removeFeature(searchFeature);
        }
      });
    },
    updateBookmark() {
      // create bookmark
      console.log("uid", this.currentUser.uid);
      console.log("bookmark List", this.bookmarks);
      var selectedCoordinates = JSON.stringify(
        this.selectedParcel.geometry.coordinates
      );

      console.log("coordinates", selectedCoordinates);
      console.log("geohash:", this.hash);

      var that = this;
      var userRef = fb.usersCollection;

      //var bookmarkRef=userRef.doc(`${this.currentUser.uid}/teams/L95NmDIgvQ6X337FiAKL/`).collection("bookmarks").where("parcel_id","==",this.selectedParcel_id)
      var bookmarkRef = userRef
        .doc(`${this.currentUser.uid}/teams/L95NmDIgvQ6X337FiAKL/`)
        .collection("bookmarks")
        .where("deleted", "==", false)
        .where("hash", "==", this.hash);
      bookmarkRef.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          // Add
          //userRef.doc(this.currentUser.uid).collection("bookmarks")
          userRef
            .doc(`${this.currentUser.uid}/teams/L95NmDIgvQ6X337FiAKL/`)
            .collection("bookmarks")
            .add({
              createdOn: new Date(),
              coordinates: selectedCoordinates,
              address: this.selectedAddress,
              pid: this.selectedParcel.properties.PID,
              //parcel_id:this.selectedParcel_id,
              objectid: this.selectedObjectId,
              lonlat: this.lonlat,
              centre: this.selectedCentre.flatCoordinates,
              hash: this.hash,
              deleted: false,
            })
            .then((ref) => {
              var feature = new Feature(new Point(fromLonLat(this.lonlat)));
              console.log(
                "add single feature polygon coordinates",
                this.selectedParcel.geometry.coordinates
              );

              var polygon = new Polygon(
                this.selectedParcel.geometry.coordinates
              );
              var afeature = new Feature(polygon);
              //afeature.setId(this.selectedParcel_id)
              afeature.setId(this.hash);

              map.getLayers().forEach(function(lyr) {
                console.log(lyr.get("name"));
                if (lyr.get("name") === "Bookmarks") {
                  //lyr.getSource().addFeatures([feature])
                  lyr.getSource().addFeature(afeature);
                  //lyr.visible=true;
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        //delete
        else {
          console.log("querySnapshot doc id", querySnapshot.docs[0].id);
          //querySnapshot.docs[0].delete()
          // yiBuVpolA9xDgUhFpn2V : document id of team perry
          userRef
            .doc(`${this.currentUser.uid}/teams/L95NmDIgvQ6X337FiAKL/`)
            .collection("bookmarks")
            .doc(querySnapshot.docs[0].id)
            //.delete();
            .update("deleted", true);
          //  console.log("Parcel ID already bookmarked:",this.selectedParcel_id)
          console.log("Object ID already bookmarked:", this.selectedObjectId);
          console.log("Hash already bookmarked:", this.hash);
          //var aselected_Parcel_id=this.selectedParcel_id
          var aselected_Objectid = this.selectedObjectId;
          var aselected_hash = this.hash;
          var select = new Select();
          select.getFeatures().clear();
          map.getLayers().forEach(function(lyr) {
            console.log(lyr.get("name"));
            if (lyr.get("name") === "Bookmarks") {
              //lyr.getSource().addFeatures([feature])
              // console.log("aselected_Parcel_id",aselected_Parcel_id)
              console.log("aselected_Objectid", aselected_Objectid);
              console.log("all features:", lyr.getSource().getFeatures());
              // var searchFeature=lyr.getSource().getFeatureById(aselected_Parcel_id)
              var searchFeature = lyr
                .getSource()
                .getFeatureById(aselected_hash);
              console.log("searchFeature", searchFeature);
              lyr.getSource().removeFeature(searchFeature);
            }
          });
        }
      });
    },
    showStreetviewPage() {
      state.lonlat = this.lonlat;
      //console.log("lonlat in olmap2",this.lonlat)
      router.push("streetview");
    },
    showNoteForm() {
      this.createpostVisible = true;
    },
    init() {
      this.initMap();
      let that = this;
      //this.setupBookmarkLayer()
      map.getLayers().forEach(function(lyr) {
        //console.log(lyr.get('name'));
        if (lyr.get("name") === "Bookmarks") {
          //console.log("that.currentUser",that.currentUser)
          fb.usersCollection
            .doc(`${that.currentUser.uid}/teams/L95NmDIgvQ6X337FiAKL/`)
            .collection("bookmarks")
            .where("deleted", "==", false)
            .get()
            .then((res) => {
              //console.log("bookmarks in olmap2",res)
              res.forEach(function(doc) {
                //console.log("doc.data.coordinates",JSON.parse(doc.data().coordinates))
                var polygon = new Polygon(JSON.parse(doc.data().coordinates));
                //console.log("polygon",polygon)
                var afeature = new Feature(polygon);
                console.log("data parcel_id", doc.data().parcel_id);
                afeature.setId(doc.data().hash);
                console.log("afeature", afeature);
                lyr.getSource().addFeature(afeature);
              });
            });
        }
      });
      this.initPopup();
      //router.push({ name: "transaction" });
    },
    initMap() {
      console.log("map");

      map = new Map({
        target: this.$refs.mapView,
        layers: [
          //mapconfig.streetmap,
          mapconfig.googlemap,
          mapconfig.satellitemap,
          //mapconfig.bookmarksmap,
          //mapconfig.indmap,
          //mapconfig.indmap2,
          mapconfig.torontoparcelmap,
          //mapconfig.torontoparcelmapMVT,
          //mapconfig.torontosaletrxMVT,
          //mapconfig.torontosaletrx,
          mapconfig.mortgagevlayer,
          //mapconfig.realnet2017vlayer,
        ],
        view: new View({
          center: olProj.transform(
            [mapconfig.x, mapconfig.y],
            "EPSG:4326",
            "EPSG:3857"
          ),
          zoom: mapconfig.zoom,
        }),
        controls: olControl.defaults({
          // Set to display OSM attributions on the bottom right control
          attributionOptions: {
            collapsible: true,
            collapsed: true,
          },
          zoom: false,
        }),
      });

      // converts number to string representation with K and M.
      // toFixed(d) returns a string that has exactly 'd' digits
      // after the decimal place, rounding if necessary.
      function numFormatter(num) {
        if (num >= 999 && num < 1000000) {
          return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
        } else if (num < 900) {
          return num; // if value < 1000, nothing to do
        }
      }

      function ToggleSatLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "GSatellite") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
      }
      // Satellite Toggle Button
      const satellite = document.createElement("div");
      satellite.className = "ol-control ol-unselectable satellite";
      satellite.innerHTML = '<button><i class="fa fa-satellite"></i></button>';
      satellite.addEventListener("click", function satelliteclick() {
        ToggleSatLayer();
      });

      map.addControl(
        new Control({
          element: satellite,
        })
      );
      function ToggleBkLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "Bookmarks") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
      }
      // Bookmark Toggle Button
      const bookmark = document.createElement("div");
      bookmark.className = "ol-control ol-unselectable bookmark";
      bookmark.innerHTML = '<button><i class="fa fa-bookmark"></i></button>';
      bookmark.addEventListener("click", function bookmarkclick() {
        ToggleBkLayer();
      });

      map.addControl(
        new Control({
          element: bookmark,
        })
      );
      if (navigator.geolocation) {
        //navigator.permissions.query({name:'geolocation'}).then(function(result){
        //  console.log("geolocation permission:",result.state)
        //})

        const locate = document.createElement("div");
        locate.className = "ol-control ol-unselectable locate";
        locate.innerHTML =
          '<button><i class="fa fa-location-arrow"></i></button>';
        locate.addEventListener("click", function locateclick() {
          // For Location Checking
          const source = new Vector();
          const layer = new VectorLayer({
            source: source,
            visible: false,
            zoom: 17,
          });
          map.addLayer(layer);
          navigator.geolocation.getCurrentPosition(
            function(pos) {
              const coords = [pos.coords.longitude, pos.coords.latitude];
              source.clear(true);
              source.addFeatures([new Feature(new Point(fromLonLat(coords)))]);
              map.getView().fit(source.getExtent(), {
                maxZoom: 17,
                duration: 500,
              });
            },
            function(error) {
              alert(`ERROR: ${error.message}`);
            },
            {
              enableHighAccuracy: true,
            }
          );
        });
        map.addControl(
          new Control({
            element: locate,
          })
        );
      }

      //  Add Geocoder
      var provider = BCAddressFind({
        //url: "https://geocoder.api.gov.bc.ca/addresses.geojson",
        //http://geogratis.gc.ca/services/geolocation/en/locate?q=777%20Dogwood%20Street,%20Coquitlam,%20BC
        //url:"https://geogratis.gc.ca/services/geolocation/en/locate",

        //https://us1.locationiq.com/v1/search?key=YOUR_ACCESS_TOKEN&q=SEARCH_STRING&format=json
        //url:"https://us1.locationiq.com/v1/search?key=6cdf16a403410b"

        url:
          "https://api.geoapify.com/v1/geocode/search?apiKey=ddd572a69e974077a7505e2b3291d66c&filter=countrycode:ca",

        //url:"http://geogratis.gc.ca/services/geolocation/en/locate"
      });

      //Instantiate with some options and add the Control
      var geocoder = new Geocoder("nominatim", {
        provider: provider,
        targetType: "text-input",
        lang: "en-US",
        placeholder: "Search by address",
        limit: 8,
        autoComplete: true,
        autoCompleteMinLength: 8,
        keepOpen: false,
        preventDefault: false,
        debug: true,
      });

      //map.addControl(geocoder);

      //recentTransactions.createLayer(map, "mapView", "recentTrans", "recentTrans-filter");

      /**
       * Custom provider for BC Address for full address search of British Columbia.
       * Factory function which returns an object with the methods getParameters
       * and handleResponse called by the Geocoder
       */
      function BCAddressFind(options) {
        var url = options.url;
        return {
          /**
           * Get the url, query string parameters and optional JSONP callback
           * name to be used to perform a search.
           * @param {object} options Options object with query, key, lang,
           * countrycodes and limit properties.
           * @return {object} Parameters for search request
           */
          getParameters: function(opt) {
            return {
              url: url,
              //callbackName: 'callback',
              params: {
                // q: opt.query,
                //addressString: opt.query,

                // for geoapify
                text: opt.query,
              },
            };
          },
          /**
           * Given the results of performing a search return an array of results
           * @param {object} data returned following a search request
           * @return {Array} Array of search results
           */
          handleResponse: function(results) {
            console.log("results:", results);
            // The API returns a GeoJSON FeatureCollection
            if (results && results && results.length) {
              return results.map(function(feature) {
                return {
                  lon: feature.geometry.coordinates[0],
                  lat: feature.geometry.coordinates[1],
                  address: {
                    // Simply return a name in this case, could also return road,
                    // building, house_number, city, town, village, state,
                    // country
                    name: feature.title,
                  },
                  bbox: feature.bbox,
                };
              });
            } else {
              return;
            }
          },
        };
      }
      let that = this;
      //Listen when an address is chosen
      geocoder.on("addresschosen", function(evt) {
        /* eslint-disable no-console */
        console.log("geocoder:" + evt.address.details.name);
        console.info(evt);
        /* eslint-enable no-console */
        //geocoderSource.clear();
        //geocoderSource.addFeature(evt.feature); // add only the last one
        //BySearch = true;
        //GetInfoFromSelected(evt.coordinate);

        that.MapOnClick(evt.coordinate);
        function remove_search_pin() {
          var remove_layer_name = "geocoder-layer";
          var layers_to_remove = [];
          map.getLayers().forEach(function(layer) {
            var layer_name = layer.getProperties().name;
            if (layer_name && layer_name.match(remove_layer_name)) {
              layers_to_remove.push(layer);
            }
          });
          for (var i = 0; i < layers_to_remove.length; i++) {
            map.removeLayer(layers_to_remove[i]);
          }
        }
        remove_search_pin();
      });

      this.html_mortgageSummary = `<div id='rcorners3' style='text-align: left;'><table class="popuptable">
  <tr >
    <td >Properties</td>
    <th>${that.mTotalPoints}</th>
  </tr>
  <tr >
    <tr >
    <td>Maturity Range</td>
    <th>${that.mMaturity}</th>
  </tr>
    <td>Total Loan</td>
    <th >${that.mSumLoan}</th>
  </tr>
  <tr>
    <td >Total Price</td>
    <th >${that.mSumPrice}</th>
  </tr>
  <tr >
  <td >Average Loan</td >
  <th w>${that.mAvgLoan}</th> 
  </tr>
  <tr>
  <td >Average Price</td>
  <th >${that.mAvgPrice}</th>
  </tr>
   <tr>
  <td>LTV</td>
  <th>${that.mAvgLTV}</th>
  </tr>
    <tr>
  <td>LTAV</td>
  <th>Not Available</th>
  </tr>
</table></font></div>`;

      function ToggleMortgageLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "mortgagevlayer") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
            if (is_visible == false) {
              mortLegend.style.display = "block";
              summarysidebar.style.display = "block";
              //summaryTable.style.display="block"
            } else if (is_visible == true) {
              mortLegend.style.display = "none";
              //summaryTable.style.display="none";
              summarysidebar.style.display = "none";
            }
          }
        });
      }

      // Mortgage Layer Toggle Button
      const mortgageLayer = document.createElement("div");
      mortgageLayer.className = "ol-control ol-unselectable mortgage";
      mortgageLayer.innerHTML =
        '<button><i class="fa fa-university"></i></button>';

      const mortLegend = document.createElement("div");
      mortLegend.className = "mortLegend";
      mortLegend.id = "rcorners2";

      mortLegend.innerHTML =
        '<div id="rcorners4" background-color:white;><table><tr style="valign:top"><td><img src="/img/APT.png" alt="APT" class="image" height="32"></td><td style="padding:.5rem">Apartment</td><td><input type="checkbox" id="aptCheckBox" value="APT" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/HOT.png" alt="HOT" class="image" height="32"></td><td style="padding:.5rem">Hotel</td><td><input type="checkbox" id="hotCheckBox" value="HOT" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/ICI.png" alt="ICI" class="image" height="32"></td><td style="padding:.5rem">ICI Land</td><td><input type="checkbox" id="iciCheckBox" value="ICI" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/IND.png" alt="IND" class="image" height="32"></td><td style="padding:.5rem">Industrial</td><td><input type="checkbox" id="indCheckBox" value="IND" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/OFF.png" alt="OFF" class="image" height="32"></td><td style="padding:.5rem">Office</td><td><input type="checkbox" id="offCheckBox" value="OFF" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/RLN.png" alt="RLN" class="image" height="32"></td><td style="padding:.5rem">Residential Land</td><td><input type="checkbox" id="rlnCheckBox" value="RLN" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/RET.png" alt="RET" class="image" height="32"></td><td style="padding:.5rem">Retail</td><td><input type="checkbox" id="retCheckBox" value="RET" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/NOTYPE.png" alt="OTH" class="image" height="32"></td><td style="padding:.5rem">Other</td><td><input type="checkbox" id="othCheckBox" value="OTH" checked="checked"></td></tr></table></div>';
      //comLegend.innerHTML="<p>Hello</p>"
      //mortgageLayer.appendChild(mortLegend);
      mortLegend.style.display = "none";
      this.html_mortgageLegend = mortLegend.innerHTML;
      mortgageLayer.addEventListener("click", function() {
        ToggleMortgageLayer();
      });

      map.addControl(
        new Control({
          element: mortgageLayer,
        })
      );

    // RealNet button
    const realnetLayer=document.createElement("div");
    realnetLayer.className="ol-control ol-unselectable realnet";
    realnetLayer.innerHTML='<button><i class="fa fa-pencil-square"></i></button>';

    map.addControl(
      new Control({
        element: realnetLayer,
      })
    );
    realnetLayer.addEventListener("click",function(){
              map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "realnet2017vlayer") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
    })



      map.on("singleclick", (evt) => {
        var clickedFeatureLayer;
        var clickedFeature = map.forEachFeatureAtPixel(
          evt.pixel,
          function(feature, layer) {
            clickedFeatureLayer = layer.get("name");
            return feature;
          },
          3
        );
        if (clickedFeature){
        console.log("clickedFeature:", clickedFeature, clickedFeatureLayer);
        if (clickedFeature) {
          if (clickedFeatureLayer == "mortgagevlayer") {
            let InvNum = clickedFeature.get("AssetType");
            let AssetType = "";
            switch (InvNum) {
              case "APT":
                AssetType = "Apartment";
                break;
              case "HOT":
                AssetType = "Hotel";
                break;
              case "ICI":
                AssetType = "ICI Land";
                break;
              case "IND":
                AssetType = "Industrial";
                break;
              case "OFF":
                AssetType = "Office";
                break;
              case "RET":
                AssetType = "Retail";
                break;
              case "RLN":
                AssetType = "Residential Land";
                break;
              case "OTH":
                AssetType = "Others";
                break;
              default:
                AssetType = "Others";
                break;
            }
            let TName = clickedFeature.get("TName");
            let Lender = clickedFeature.get("Lender");
            let MAmt = clickedFeature.get("MAmt");
            let Price = clickedFeature.get("Price");
            let LTV = ((MAmt / Price) * 100).toFixed(1) + "%";
            let LTAV = "Not Available";
            let MatDate = new Date(
              clickedFeature.get("MDate")
            ).toLocaleDateString();
            let TDate = new Date(
              clickedFeature.get("TDate")
            ).toLocaleDateString();
            let Borrower = clickedFeature.get("Buyer");
            let Address = clickedFeature.get("Address");

            LTV = typeof LTV === "undefined" ? "" : LTV;
            MAmt = numFormatter(MAmt);
            MAmt = typeof MAmt === "undefined" ? "" : "$" + MAmt;
            //MAmt="$" + MAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            Price = numFormatter(Price);
            Price = typeof Price === "undefined" ? "" : "$" + Price;
            //Price="$" + Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let apopupContent = `<div style='text-align: left;'><font size="2"  ><table class="popuptable">
  <caption>
  <p style='color:blue;'>${TName}</p>
  </caption>
    <tr  valign="top">
    <th width="30%">Address</th>
    <td colspan="2";width="75%">${Address}</td>
  </tr>
  <tr  valign="top">
    <th width="30%">Type</th>
    <td colspan="2";width="75%">${AssetType}</td>
  </tr>
  <tr valign="top">
    <th>Borrower</th>
    <td colspan="2">${Borrower}</td>
  </tr>
    <tr valign="top">
    <th>Lender</th>  
    <td colspan="2">${Lender}</td>      
  </tr> 
    <tr valign="top">
    <th>Maturity</th>
    <td colspan="2">${MatDate}</td>
  </tr>
      <tr valign="top">
    <th  width="35%">Sale Date</th>
    <td colspan="2">${TDate}</td>
  </tr>
  <tr >
  <th >Loan</th > <td >${MAmt}</td>
  </tr>
  <tr >
 <th >Price</th><td >${Price}</td>
  </tr>
    <tr >
  <th >LTV</th ><td>${LTV}</td>
  </tr>
  <tr>
   <th >LTAV</th><td>${LTAV}</td>
  </tr>
</table></font></div>`;
            this.popupContent = apopupContent;
            document.getElementById("popupbottombar").style.visibility =
              "hidden";
            popup.setPosition(evt.coordinate);
          } else if (clickedFeatureLayer == "GreaterTorontoParcel") {
            var icoordinate = evt.coordinate;
            document.getElementById("popupbottombar").style.visibility =
              "visible";
            this.MapOnClick(icoordinate);
          } else if (clickedFeatureLayer == "realnet2017vlayer") {
            console.log("Sale Trx:",clickedFeature.get("TName"),clickedFeature.get("Price"))
          } 
          
          else {
            console.log("No layer detected and hence no popup!");
          }
        } 
        }else {
          var icoordinate = evt.coordinate;
            document.getElementById("popupbottombar").style.visibility =
              "visible";
            this.MapOnClick(icoordinate);
        }
      });

    map.on('moveend',function(){
      //console.log("map move end")
      if( mapconfig.mortgagevlayer.getVisible()==true){
      that.FilterByDateRange()
      }
    })
    }, // end of initMap



    initPopup() {
      // Vienna marker
      popup = new Overlay({
        positioning: "bottom-center",
        element: this.$refs.popup,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
        //offset: [-140 + 120, 0],
      });
      console.log("popup", popup);
      map.addOverlay(popup);
    },
    initData() {},
    popupcloser() {
      popup.setPosition(undefined);
      //   closer.blur();
      return false;
    },

    MapOnClick(coordinate) {
      var apopupContent = "";
      var a_address = "";
      var currentZoom = map.getView().getZoom();
      console.log("current zoom:", currentZoom);
      //initialize the geohash
      this.hash = null;
      this.selectedCentre = null;
      console.log("MapOnClick coordinate input:", coordinate);
      var url = mapconfig.torontoparcelmap
        .getSource()
        .getFeatureInfoUrl(
          coordinate,
          map.getView().getResolution(),
          map.getView().getProjection(),
          {
            INFO_FORMAT: "application/json",
            propertyName: "the_geom,Full_Address,City",
          }
        );
      console.log("url:" + url);
      url = proxyurl1 + url;

      // Ref: https://stackoverflow.com/questions/54042152/uncaught-in-promise-typeerror-cannot-set-property-playername-of-undefined-a/54042367
      let that = this; // this is needed as 'this' is not understood after axio.get.then(function)
      axios.get(url).then(function(response) {
        console.log("Toronto WMS response:", response);
        if (response.data.features.length > 0) {
          var feature = response.data.features[0];
          that.selectedParcel = feature;
          var g = feature.geometry; //get geometry of a featyre in your vector layer
          console.log("g:", g);
          var vertices = g.coordinates; //get vertices as array of POINT geometry in an array

          map.getLayers().forEach((layer) => {
            if (layer && layer.get("name") === "Selected Location") {
              //alert("Remove Vector Layer");
              map.removeLayer(layer);
            }
          });
          that.lonlat = olProj.transform(coordinate, "EPSG:3857", "EPSG:4326");
          var lon = that.lonlat[0];
          var lat = that.lonlat[1];
          console.log("lonlat:", that.lonlat);
          console.log("vertices", vertices);

          var polygon = new Polygon(vertices);
          console.log("polygon", polygon);

          that.selectedCentre = polygon.getInteriorPoint();
          console.log("coordinate", coordinate);
          console.log("aCenter", that.selectedCentre);
          // fix for parcel selected but cannot get blue polygon redraw. e.g. 25450 lougheed Maple Ridge
          if (!that.selectedCentre.flatCoordinates[0]) {
            var aPoint = new Point(coordinate);
            that.selectedCentre = aPoint;
            console.log("aPoint", aPoint);
          }
          that.hash = that.computeGeoHash(that.selectedCentre);
          console.log("geohash:", that.hash);
          EventBus.$emit("get_hash", that.hash);

          a_address =
            feature.properties.Full_Address + " " + feature.properties.City;
          that.popupContent = `<p align="left">${a_address} </p>`;
          popup.setPosition(coordinate);

          var styles = [
            new Style({
              stroke: new Stroke({
                color: "blue",
                width: 3,
              }),
              fill: new Fill({
                color: "rgba(255, 255, 255, 0.1)",
              }),
            }),
          ];

          //that.checkBookmarked()

          // Create feature with polygon.
          var afeature = new Feature(polygon);
          afeature.setStyle(styles);
          console.log("afeature:", afeature);
          // Create vector source and the feature to it.
          var vectorSource = new Vector();
          vectorSource.addFeature(afeature);
          // Create vector layer attached to the vector source.
          var vectorLayer = new VectorLayer({
            name: "Selected Location",
            //title:"Selected Location",
            source: vectorSource,
            style: styles,
            visible: true,
          });
          //vectorLayer.setZIndex(99)

          // Add the vector layer to the map.
          map.addLayer(vectorLayer);

          //map.getView().setZoom(18);
        } // end of if (feature)
      }); // end of get(URL)
    }, // end of MapOnClick

    FileUpload: function(file, path = "/", callback) {
      if (!file) {
        alert("Please select a file to upload");
        return;
      }
      let extension = file.name.split(".").pop();
      let newfilename = this.currentUser.uid + "." + extension;
      let newfile = new File([file], newfilename);
      console.log("newfilename", newfile.name);
      let uploadTask = fb.storage
        .ref()
        .child(`images/${newfile.name}`)
        .put(newfile);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          callback(error, null);
        },
        (success) => {
          let downloadURL = uploadTask.snapshot.ref.getDownloadURL();
          callback && callback(null, downloadURL);
        }
      );
    },
  }, // end of methods
  activated() {
    if (map) {
      map.updateSize();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init(),
        EventBus.$on("delete_bookmark_parcel", (data) => {
          console.log("Need to delete this parcel in bookmarklayer:", data);
          this.deleteParcelonBookmarkLayer(data);
        }),
        EventBus.$on("clear_bookmarkLayer", () => {
          this.clearBookmarkLayer();
        }),
        EventBus.$on("go2map", (data) => {
          var polygonCoord = data;
          console.log("get go2map", polygonCoord);
          this.MapOnClick(polygonCoord);
          map.getView().setCenter(polygonCoord);
          //map.getView().setZoom(16)
          map.updateSize();
        });
    });
  },
};
</script>

<style>
#mapView {
  height: 97vh;
  width: 99vw;
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: -1;
}
.ol-popup {
  position: absolute;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 250px;
  font-size: 14px;
  /*visibility: hidden;*/
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: #fff;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.satellite {
  left: 0.5em;
  top: 7.5em;
}

.bookmark {
  left: 0.5em;
  top: 10em;
}

.locate {
  top: 12.5em;
  left: 0.5em;
}

.recentTrans {
  visibility: hidden;
  top: 15em;
  left: 0.5em;
}

.recentTrans-filter {
  top: 15em;
  left: 2.85em;
}

.ol-geocoder.gcd-txt-container {
  width: 15em;
  left: 3.3em;
}

.ol-geocoder .gcd-txt-control {
  height: 2.8em;
  top: 1.1em;
}

.mainmenu {
  position: absolute;
  top: 50%;
  left: 0.1em;
  margin: 3px 0;
}

.testbutton {
  position: absolute;
  top: 70%;
  left: 0.1em;
  margin: 3px 0;
}

.avatar {
  vertical-align: middle;
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.custom-file-upload {
  border: 1px none #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}

.ol-timeline {
  font-size: 2em;
  position: absolute;
  top: 90%;
}
.ol-timeline .ol-scroll {
  height: 2em;
}
.ol-timeline .ol-features {
  text-align: center;
}

.mortgage {
  left: 0.5em;
  top: 17.5em;
}

.realnet {
  left: 0.5em;
  top: 15.5em;
  display:none;
}

.mortLegend {
  position: absolute;
  text-align: left;
  top: 220%;
  left: 70em;
  right: 0.5em;
  width: 180px;
  background-color: white;
}
.summaryBox {
  left: 2.9em;
  top: 17.5em;
}
.summaryTable {
  position: absolute;
  text-align: left;
  top: -700%;
  left: 63.5em;
  right: 5.5em;
  width: 180px;
  background-color: white;
}

.popuptable {
  border-collapse: collapse;
}

.popuptable td,
.popuptable th {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
}
caption {
  text-align: -webkit-left;
}
#rcorners2 {
  border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 220px;
}
#rcorners3,
#rcorners4 {
  border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 360px;
  margin-top: 20px;
}
#rangeslider {
  width: 65%;
}
#summarysidebar {
  border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 390px;
  background-color: lightgray;
}
#db1 {
  display: none;
}
#summarysidebar {
  display: none;
}

@media (orientation: landscape) {
  .mortgage {
    display: block;
  }
}
@media (orientation: portrait) {
  .mortgage {
    display: none;
  }
  .ol-timeline {
    display: none;
  }
}
@media (orientation: portrait) {
  .summarysidebar {
    display: none;
  }
}

.sidebar {
  z-index: 9999;
  background-color: #f2f2f2;
  width: 20%;
  margin: 1% 1% 1% 1%;
  padding: 1% 1% 1% 1%;
  outline: none;
  border-style: solid;
  border-width: 3px;
  border-radius: 2px;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}
.right {
  position: absolute;
  /* left: 63.5em; */
  right: 0.5em;
}
</style>
